import React, { useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import Modal from 'react-modal';
import { farmFastUnbond, farmUnbond } from 'z/elrond';
import { FarmType, NonFungibleTokenBalanceType, StakedLpTokenType } from 'z/types';
import { convertWeiToEsdt, ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, toastError } from 'z/utils';
import { FARM_TOKEN_UNLOCK_EPOCHS_MAP } from 'config';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#1B0921',
        color: 'white',
        maxWidth: '90%',
        width: '600px',
    },
};

interface FarmClaimUnbondedButtonProps {
    farm: FarmType;
    exoticLps: NonFungibleTokenBalanceType[];
    unbondableIndexes: number[];
    netstatsRedux: any;
}

// Add these constants at the top of your file
const BRONZE_UNSTAKING_FEES: [number, number, number][] = [
    [0, 1, 3],
    [2, 3, 2],
    [4, 20, 1],
];

const SILVER_UNSTAKING_FEES: [number, number, number][] = [
    [0, 9, 15],
    [10, 27, 10],
    [28, 63, 5],
];

const GOLD_UNSTAKING_FEES: [number, number, number][] = [
    [0, 30, 45],
    [31, 90, 30],
    [91, 210, 15],
];

const FAST_UNBONDING_FEE_DENOMINATION = 100;

export const FarmClaimUnbondedButton: React.FC<FarmClaimUnbondedButtonProps> = ({
    farm,
    exoticLps,
    unbondableIndexes,
    netstatsRedux,
}) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkedLPs, setCheckedLPs] = useState<number[]>([]);

    const unbondingLPs = exoticLps.filter(
        (lp, index) =>
            lp.stake_token_type &&
            lp.stake_token_type >= StakedLpTokenType.Bronze &&
            lp.unbonding_start_epoch &&
            !unbondableIndexes.includes(index),
    );

    const hasUnbondingLPs = unbondingLPs.length > 0;

    const handleClaimClick = async () => {
        if (unbondableIndexes.length > 0) {
            await unbondFarmTokens();
        } else if (hasUnbondingLPs) {
            setIsModalOpen(true);
        }
    };

    const unbondFarmTokens = async () => {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (unbondableIndexes.length === 0) {
            error = 'No unbondable farm token';
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments: TokenTransfer[] = unbondableIndexes.map((index) =>
            TokenTransfer.metaEsdtFromBigInteger(
                exoticLps[index].collection,
                exoticLps[index].nonce,
                exoticLps[index].balance,
            ),
        );

        await farmUnbond(farm.farm_address, payments, address);
    };

    const handleFastClaim = async () => {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (checkedLPs.length === 0) {
            error = 'No unbondable farm token';
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments: TokenTransfer[] = checkedLPs.map((index) =>
            TokenTransfer.metaEsdtFromBigInteger(
                unbondingLPs[index].collection,
                unbondingLPs[index].nonce,
                unbondingLPs[index].balance,
            ),
        );

        await farmFastUnbond(farm.farm_address, payments, address);

        setIsModalOpen(false);
    };

    const handleCheckLP = (index: number) => {
        setCheckedLPs((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    };

    const calculateFee = (lp: NonFungibleTokenBalanceType) => {
        if (!netstatsRedux.elrondStats || !lp.unbonding_start_epoch) return '0';

        const currentEpoch = netstatsRedux.elrondStats.epoch;
        const epochsPassed = currentEpoch - lp.unbonding_start_epoch;
        console.log(lp, epochsPassed);
        let feeRanges;
        switch (lp.stake_token_type) {
            case StakedLpTokenType.UnbondingBronze:
                feeRanges = BRONZE_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingSilver:
                feeRanges = SILVER_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingGold:
                feeRanges = GOLD_UNSTAKING_FEES;
                break;
            default:
                return '0';
        }

        let feePercentage = 0;
        for (const [start, end, percentage] of feeRanges) {
            if (epochsPassed >= start && epochsPassed <= end) {
                feePercentage = percentage;
                break;
            }
        }

        const feeMultiplier = feePercentage / FAST_UNBONDING_FEE_DENOMINATION;
        return convertWeiToEsdt(lp.balance).multipliedBy(feeMultiplier).toString();
    };

    const calculateETA = (lp: NonFungibleTokenBalanceType) => {
        if (!netstatsRedux.elrondStats || !lp.unbonding_start_epoch) return 'N/A';
        const currentEpoch = netstatsRedux.elrondStats.epoch;
        const epochsLeft = lp.unbonding_start_epoch + FARM_TOKEN_UNLOCK_EPOCHS_MAP[lp.stake_token_type!] - currentEpoch;
        return `${epochsLeft} epochs`;
    };

    const calculateFeePercentage = (lp: NonFungibleTokenBalanceType) => {
        if (!netstatsRedux.elrondStats || !lp.unbonding_start_epoch) return 'N/A';

        const currentEpoch = netstatsRedux.elrondStats.epoch;
        const epochsPassed = currentEpoch - lp.unbonding_start_epoch;

        let feeRanges;
        switch (lp.stake_token_type) {
            case StakedLpTokenType.UnbondingBronze:
                feeRanges = BRONZE_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingSilver:
                feeRanges = SILVER_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingGold:
                feeRanges = GOLD_UNSTAKING_FEES;
                break;
            default:
                return 'N/A';
        }

        for (const [start, end, percentage] of feeRanges) {
            if (epochsPassed >= start && epochsPassed <= end) {
                return `${percentage}%`;
            }
        }

        return 'N/A';
    };

    const calculateEpochsUntilFeeDecrease = (lp: NonFungibleTokenBalanceType) => {
        if (!netstatsRedux.elrondStats || !lp.unbonding_start_epoch) return 'N/A';

        const currentEpoch = netstatsRedux.elrondStats.epoch;
        const epochsPassed = currentEpoch - lp.unbonding_start_epoch;

        let feeRanges;
        switch (lp.stake_token_type) {
            case StakedLpTokenType.UnbondingBronze:
                feeRanges = BRONZE_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingSilver:
                feeRanges = SILVER_UNSTAKING_FEES;
                break;
            case StakedLpTokenType.UnbondingGold:
                feeRanges = GOLD_UNSTAKING_FEES;
                break;
            default:
                return 'N/A';
        }

        for (let i = 0; i < feeRanges.length; i++) {
            const [start, end, _] = feeRanges[i];
            if (epochsPassed >= start && epochsPassed <= end) {
                if (i === feeRanges.length - 1) {
                    return 'N/A'; // Already at the lowest fee
                }
                return (feeRanges[i + 1][0] - epochsPassed).toString();
            }
        }

        return 'N/A';
    };

    return (
        <>
            <button
                className="farm_card_individual_element_but text-center"
                disabled={hasPendingTransactions || (!unbondableIndexes.length && !hasUnbondingLPs)}
                onClick={handleClaimClick}
            >
                {unbondableIndexes.length > 0 ? 'Claim' : hasUnbondingLPs ? 'Fast Claim' : 'Nothing To Claim'}
            </button>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="container">
                    <h2 className="text-center mb-4">Claim unbonding LPs</h2>
                    <p className="text-center mb-4">
                        The LPs below are currently unbonding.
                        <br />
                        You can select one or more for fast claiming.
                    </p>
                    <div className="row">
                        <div className="col-12" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {unbondingLPs.map((lp, index) => (
                                <div
                                    key={index}
                                    className={`farm_card mb-3 p-3 ${checkedLPs.includes(index) ? 'selected' : ''}`}
                                    onClick={() => handleCheckLP(index)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="farm_card_header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title mb-0">
                                            {lp.stake_token_type === StakedLpTokenType.UnbondingBronze
                                                ? 'Bronze'
                                                : lp.stake_token_type === StakedLpTokenType.UnbondingSilver
                                                ? 'Silver'
                                                : 'Gold'}{' '}
                                            LP: {convertWeiToEsdt(lp.balance).toString()}
                                        </h5>
                                        <div className="form-check" onClick={(e) => e.stopPropagation()}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={checkedLPs.includes(index)}
                                                onChange={() => handleCheckLP(index)}
                                                id={`checkbox-${index}`}
                                            />
                                            <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                Select for fast claim
                                            </label>
                                        </div>
                                    </div>
                                    <div className="farm_card_body">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 mb-2 mb-sm-0">
                                                <p className="card-text mb-1">{calculateETA(lp)} until unbonded</p>
                                                <p className="card-text mb-0">
                                                    {calculateEpochsUntilFeeDecrease(lp)} epochs until fee decreases
                                                </p>
                                            </div>
                                            <div className="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0">
                                                <p className="mb-1">Fast claim fee</p>
                                                <p className="mb-0">
                                                    Amount: {calculateFee(lp)} ({calculateFeePercentage(lp)})
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <button
                                className="btn btn-primary w-100"
                                onClick={handleFastClaim}
                                disabled={checkedLPs.length === 0}
                            >
                                Fast claim selected
                            </button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-secondary w-100" onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
