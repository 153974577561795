import React, { useEffect, useState } from 'react';
import {
  useGetAccount,
  useGetNetworkConfig,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import Modal from 'react-modal';
import { getTokenBalanceFromApi } from 'z/elrond';
import { stakeLps } from 'z/elrond/farm';
import { FarmType } from 'z/types';
import {
  formatNumber,
  convertEsdtToWei,
  createTokenTicker,
  convertWeiToEsdt,
  DEFAULT_DECIMALS,
  ERROR_CONNECT_WALLET,
  ERROR_INVALID_NUMBER,
  ERROR_NOT_ENOUGH_BALANCE,
  // getTokenLogo,
  isPositiveOrZeroBigNumber,
  ZERO_STRING,
} from 'z/utils';

const customStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
  },
  backgroundColor: '#1B0921 !important',
};

export const AddToStakeModal = ({
  isModalOpen,
  onCloseModal,
  farmAddress,
  // maxLpAmount,
  lpTokenId,
  // farm,
}: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  farmAddress: string;
  maxLpAmount: string;
  lpTokenId: string;
  farm: FarmType;
}) => {
  const { chainID } = useGetNetworkConfig();
  const { address } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  
  const [selectedLockType, setSelectedLockType] = useState(1);
  const [stakeTokenBalance, setStakeTokenBalance] = useState<string>(ZERO_STRING);
  const [stakeTokenAmount, setStakeTokenAmount] = useState<string>(ZERO_STRING);
  const [stakeTokenAmountError, setStakeTokenAmountError] = useState<string>('');

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    (async () => {
      const _tokenBalanceInfo = await getTokenBalanceFromApi(address, lpTokenId);
      setStakeTokenBalance(_tokenBalanceInfo? _tokenBalanceInfo.balance : ZERO_STRING);
    })();
  }, [address, hasPendingTransactions]);

  function onChangeStakeTokenAmount(value: string) {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveOrZeroBigNumber(value)) {
        error = ERROR_INVALID_NUMBER;
    } else if (convertEsdtToWei(value).comparedTo(stakeTokenBalance) > 0) {
        error = ERROR_NOT_ENOUGH_BALANCE;
    }
    
    setStakeTokenAmountError(error);
    setStakeTokenAmount(value);
    
    return error;
  }

  function onMaxStakeTokenAmount() {
    if (address) {
      onChangeStakeTokenAmount(convertWeiToEsdt(stakeTokenBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed());
    }
  }

  const lockTypeButton = (value: number, label: string) => {
    return (
      <div className='col-12 col-md-4'>
        <div
          className={`farm_card_individual_element_but text-center ${
            selectedLockType === value && 'selected'
          }`}
          onClick={() => setSelectedLockType(value)}
        >
          {label}
        </div>
      </div>
    );
  };

  const handleStakeLps = async () => {
    if (selectedLockType === 0) {
      window.alert('You must select the stake lock type');
      return;
    }
    await stakeLps(
      farmAddress,
      lpTokenId,
      convertEsdtToWei(stakeTokenAmount),
      selectedLockType,
      chainID,
    );
    closeModal();
  };

  function closeModal() {
    onCloseModal();
    onChangeStakeTokenAmount(ZERO_STRING);
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="select-token-modal px-4 pb-4" style={{ color: 'white' }}>
          <div
              className="mt-4 mb-3 text-center"
              // style={{ color: 'white', fontSize: '1.1rem' }}
              style={{
                color: '#F1DC46',
                marginLeft: '15px',
                fontSize: '1.25rem',
              }}
          >
            Add LPs to stake
          </div>

          <div className="d-flex flex-column gap-1 mt-2">
            <div className="vesta_x_swap_input_box">
              <div className="d-flex align-items-center">
                  <input
                      className="swap_input"
                      type="number"
                      value={stakeTokenAmount}
                      onChange={(e) => onChangeStakeTokenAmount(e.target.value)}
                  />
                  <button className="add-liquidity-selected-token-buttton">
                      {/* <img src={getTokenLogo(lpTokenId)} alt="logo" width="30px" /> */}
                      {createTokenTicker(lpTokenId)}
                  </button>
              </div>
                <div className="d-flex justify-content-between mt-1">
                    <div className="input-token-error">{stakeTokenAmountError}</div>
                    <div
                      className="add-liquidity-input-token-balance-box"
                      onClick={onMaxStakeTokenAmount}
                    >
                        <div className="">Balance:&nbsp;</div>
                        <div style={{ color: 'white' }}>
                          {formatNumber(convertWeiToEsdt(stakeTokenBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS), DEFAULT_DECIMALS)}
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div className='mt-3'>LP Stake lock type</div>
          <div className="row mt-3">
            {lockTypeButton(1, 'Standard')}
            {lockTypeButton(2, 'Premium')}
            {lockTypeButton(3, 'Elite')}
          </div>
          <div className="row mt-3 mb-2 text-center" style={{ color: '#ccc', fontSize: '1rem' }}>
            <div className='col-12 col-md-4'>
              <div>1.0x</div>
              <div>0.5x (Unstaking)</div>
              <div>20 Days Latency</div>
            </div>
            <div className='col-12 col-md-4'>
              <div>1.6x</div>
              <div>0.96x (Unstaking)</div>
              <div>63 Days Latency</div>
            </div>
            <div className='col-12 col-md-4'>
              <div>2.5x</div>
              <div>1.75x (Unstaking)</div>
              <div>210 Days Latency</div>
            </div>
          </div>

        <div className='d-flex justify-content-between w-100 mt-4'>
          <div
            className="farm_card_individual_element_but text-center"
            onClick={handleStakeLps}
          >
            Stake LP
          </div>
          <div
            className="farm_card_individual_element_but text-center"
            onClick={closeModal}
          >
            Dismiss
          </div>
        </div>
      </div>
    </Modal>
  );
};
