import React, { useEffect, useState } from 'react';
// import { TokenTransfer } from '@multiversx/sdk-core/out';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
  useGetAccount,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { Col } from 'react-bootstrap';
// import { elrondFixBrokenVlps } from 'z/elrond';
import { InfoTooltip } from 'components';
import { FARM_TOKEN_UNLOCK_EPOCHS_MAP } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { farmUnbond } from 'z/elrond';
import {
  FarmType,
  NonFungibleTokenBalanceType,
  StakedLpTokenType,
  SwapPoolType,
} from 'z/types';
import {
  convertWeiToEsdt,
  ERROR_CONNECT_WALLET,
  ERROR_TRANSACTION_ONGOING,
  toastError,
} from 'z/utils';
import { AddToStakeModal } from './AddToStakeModal';
import { RemoveFromStakeModal } from './RemoveFromStakeModal';
import { FarmClaimUnbondedButton } from './FarmClaimUnbondedButton';

export const StakeLpsBox = ({
  pool,
  farm,
  exoticLps,
}: {
  pool: SwapPoolType;
  farm: FarmType;
  exoticLps: NonFungibleTokenBalanceType[];
}) => {
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { address } = useGetAccount();

  const netstatsRedux = useAppSelector(selectNetstats);

  const [unbondableIndexes, setUnbondableIndexes] = useState<number[]>([]);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState<boolean>(false);
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!netstatsRedux.elrondStats) return;

    const _unbondableIndexes = [];
    for (let i = 0 ; i < exoticLps.length; i++) {
      const lp = exoticLps[i];
      if (lp.stake_token_type && lp.stake_token_type >= StakedLpTokenType.Bronze && lp.unbonding_start_epoch) {
        if (lp.unbonding_start_epoch + FARM_TOKEN_UNLOCK_EPOCHS_MAP[lp.stake_token_type] <= netstatsRedux.elrondStats.epoch) {
          _unbondableIndexes.push(i);
        }
      }
    }
    setUnbondableIndexes(_unbondableIndexes);
  }, [exoticLps, netstatsRedux.elrondStats]);

  const reduce = (items: NonFungibleTokenBalanceType[]) => {
    if (items.length === 0) return 0;
    return items
      .reduce(
        (prev, crt) => (prev = prev.plus(convertWeiToEsdt(crt.balance))),
        convertWeiToEsdt('0'),
      )
      .toNumber();
  };

  async function unbondFarmTokens() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else if (unbondableIndexes.length === 0) {
      error = 'No unbondable farm token';
    }

    if (error) {
      toastError(error);
      return;
    }
    
    const payments: TokenTransfer[] = unbondableIndexes.map(
      index => TokenTransfer.metaEsdtFromBigInteger(
        exoticLps[index].collection,
        exoticLps[index].nonce,
        exoticLps[index].balance,
      )
    );

    await farmUnbond(
      farm.farm_address,
      payments,
      address,
    );
  }

  // async function fixBrokenVlps() {
  //   if (!address) {
  //     toastError(ERROR_CONNECT_WALLET);
  //     return;
  //   }
  //   if (hasPendingTransactions) {
  //     toastError(ERROR_TRANSACTION_ONGOING);
  //     return;
  //   }
  //   if (exoticLps.length === 0) {
  //     toastError('You do not have any vLP');
  //     return;
  //   }
  //   const brokenVlps = exoticLps.filter(lp => lp.stake_epoch === 0);
  //   if (brokenVlps.length === 0) {
  //     toastError('You do not have any broken vLP');
  //     return;
  //   }
  //   const payments = brokenVlps.map(lp => TokenTransfer.metaEsdtFromBigInteger(
  //     lp.collection,
  //     lp.nonce,
  //     lp.balance
  //   ));

  //   await elrondFixBrokenVlps(
  //     address,
  //     farm.farm_address,
  //     payments,
  //   );
  // }

  return (
    <>
      <Col className="p-1 position-relative" xs={4}>
        <div className="farm_card_individual_element">Bronze LPs</div>

        <InfoTooltip title='BRONZE LPs represent the Exotic LPs generated while staking LPs in the Standard Mode.' />
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.Bronze,
            ),
          ).toLocaleString()}
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.UnbondingBronze,
            ),
          ).toLocaleString()}{' '}
          Unbonding
        </div>
      </Col>
      <Col className="p-1 position-relative" xs={4}>
        <div className="farm_card_individual_element">Silver LPs</div>

        <InfoTooltip title='SILVER LPs represent the Exotic LPs generated while staking LPs in the Premium Mode.' />
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.Silver,
            ),
          ).toLocaleString()}
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.UnbondingSilver,
            ),
          ).toLocaleString()}{' '}
          Unbonding
        </div>
      </Col>
      <Col className="p-1 position-relative" xs={4}>
        <div className="farm_card_individual_element">Gold LPs</div>

        <InfoTooltip title='GOLD LPs represent the Exotic LPs generated while staking LPs in the Elite Mode.' />
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.Gold,
            ),
          ).toLocaleString()}
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <div className="farm_card_individual_element text-center">
          {reduce(
            exoticLps.filter(
              (lp) => lp.stake_token_type === StakedLpTokenType.UnbondingGold,
            ),
          ).toLocaleString()}{' '}
          Unbonding
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <div
          className="farm_card_individual_element_but text-center"
          onClick={() => {
            if (!address) {
              toastError(ERROR_CONNECT_WALLET);
              return;
            }
            if (hasPendingTransactions) {
              toastError(ERROR_TRANSACTION_ONGOING);
              return;
            }
            setIsStakeModalOpen(true);
          }}
        >
          Stake LP
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <div
          className="farm_card_individual_element_but text-center"
          onClick={() => {
            if (!address) {
              toastError(ERROR_CONNECT_WALLET);
              return;
            }
            if (hasPendingTransactions) {
              toastError(ERROR_TRANSACTION_ONGOING);
              return;
            }
            setIsUnstakeModalOpen(true);
          }}
        >
          Withdraw
        </div>
      </Col>
      <Col className="p-1" xs={4}>
        <FarmClaimUnbondedButton
          farm={farm}
          exoticLps={exoticLps}
          unbondableIndexes={unbondableIndexes}
          netstatsRedux={netstatsRedux}
        />
      </Col>

      {/* <Col className="p-1" xs={4}>
        <div
          className="farm_card_individual_element_but text-center"
          onClick={fixBrokenVlps}
        >
          Fix Broken vLPs
        </div>
      </Col> */}

      <AddToStakeModal
        isModalOpen={isStakeModalOpen}
        onCloseModal={() => setIsStakeModalOpen(false)}
        farmAddress={farm.farm_address}
        maxLpAmount={pool.lp_token_balance}
        lpTokenId={pool.lp_token_id}
        farm={farm}
      />
      <RemoveFromStakeModal
        isModalOpen={isUnstakeModalOpen}
        onCloseModal={() => setIsUnstakeModalOpen(false)}
        farm={farm}
        farmAddress={farm.farm_address}
        maxLpAmount={pool.lp_token_balance}
        lpTokenId={pool.lp_token_id}
        exoticLps={exoticLps}
      />
    </>
  );
};
